import { template as template_9e0bb7649cf742639fe57da690476470 } from "@ember/template-compiler";
const FKLabel = template_9e0bb7649cf742639fe57da690476470(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
