import { template as template_31cf7a638dd948a0955bbd04e13e2b70 } from "@ember/template-compiler";
const WelcomeHeader = template_31cf7a638dd948a0955bbd04e13e2b70(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
