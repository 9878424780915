import { template as template_e6b6e0e31ebc4134a39e6c80cbd33cb3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e6b6e0e31ebc4134a39e6c80cbd33cb3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
