import { template as template_b7f63be5df254582b61db2be9df81b4a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_b7f63be5df254582b61db2be9df81b4a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
